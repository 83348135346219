export async function renderPdfToImages(pdfData: Uint8Array): Promise<string> {
  if (!import.meta.client) {
    throw new Error("This function is only available on the client side.");
  }

  // Dynamically import pdfjsLib
  const pdfjsLibModule = await import("pdfjs-dist/build/pdf");
  const pdfjsLib = pdfjsLibModule as typeof import("pdfjs-dist/build/pdf");

  // Set the workerSrc property
  const workerSrcUrl = await getPdfWorkerSrc();

  if (!workerSrcUrl) {
    throw new Error("Unable to locate pdf.worker.js");
  }

  // @ts-ignore
  pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrcUrl;

  // Array to hold the image elements for each page
  const images: HTMLImageElement[] = [];

  // Load the PDF
  const loadingTask: pdfjsLib.PDFDocumentLoadingTask = pdfjsLib.getDocument({
    data: pdfData,
  });
  const pdf: pdfjsLib.PDFDocumentProxy = await loadingTask.promise;

  // Iterate through each page
  for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
    const page: pdfjsLib.PDFPageProxy = await pdf.getPage(pageNum);

    // Set the desired scale (adjust as needed)
    const scale = 1.5;
    const viewport: pdfjsLib.PageViewport = page.getViewport({
      scale,
    });

    // Create a canvas element to render the page
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Canvas 2D context is not available.");
    }

    canvas.width = viewport.width;
    canvas.height = viewport.height;

    // Render the page into the canvas context
    const renderContext: pdfjsLib.RenderParameters = {
      canvasContext: context,
      viewport: viewport,
      intent: "display",
    };
    const renderTask: pdfjsLib.RenderTask = page.render(renderContext);
    await renderTask.promise;

    // Convert to high contrast black and white
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      const shade = Math.floor(avg / 8) * 8; // 32 shades: 0, 8, 16, ..., 240, 248
      data[i] = data[i + 1] = data[i + 2] = shade;
    }
    context.putImageData(imageData, 0, 0);

    // Convert the canvas to an image element
    const image = new Image();
    image.src = canvas.toDataURL("image/png");

    await new Promise<void>((resolve, reject) => {
      image.onload = () => resolve();
      image.onerror = (error) => reject(error);
    });

    images.push(image);
  }

  // Concatenate all images vertically into one canvas
  let totalHeight = 0;
  let maxWidth = 0;

  for (const img of images) {
    totalHeight += img.height;
    if (img.width > maxWidth) {
      maxWidth = img.width;
    }
  }

  const finalCanvas: HTMLCanvasElement = document.createElement("canvas");
  finalCanvas.width = maxWidth;
  finalCanvas.height = totalHeight;
  const finalContext = finalCanvas.getContext("2d");

  if (!finalContext) {
    throw new Error("Canvas 2D context is not available.");
  }

  let currentY = 0;
  for (const img of images) {
    finalContext.drawImage(img, 0, currentY);
    currentY += img.height;
  }

  // Convert the final canvas to a base64 image
  const base64Image = finalCanvas.toDataURL("image/png");

  return base64Image;
}

// Helper function to get the workerSrc URL
async function getPdfWorkerSrc(): Promise<string | null> {
  if (!import.meta.client) {
    throw new Error("This function is only available on the client side.");
  }
  try {
    const workerSrcModule = await import(
      "pdfjs-dist/legacy/build/pdf.worker.min.mjs?url"
    );
    const workerSrc = workerSrcModule.default || workerSrcModule;
    return workerSrc;
  } catch (error) {
    console.error("Failed to import pdf.worker.js as a URL:", error);
    return null;
  }
}
